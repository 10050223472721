import React, { Suspense } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";

import { showRouterFallback } from "./fallback/Default";

import "./App.css";

const ScanVisit = React.lazy(() => import('./page/ScanVisit'));
const Authentication = React.lazy(() => import('./page/Authentication'));
const Dashboard = React.lazy(() => import('./page/Dashboard'));
const UploadPhoto = React.lazy(() => import('./page/UploadPhoto'));
const StatusPhoto = React.lazy(() => import('./page/StatusPhoto'));
const StockReport = React.lazy(() => import('./page/StockReport'));
const SalesmanWholesaler = React.lazy(() => import('./page/SalesmanWholesaler'));
const RetailClassification = React.lazy(() => import('./page/RetailClassification'));
const SalesmanHomepage = React.lazy(() => import('./page/SalesmanHomepage'));
const SalesmanPresence = React.lazy(() => import('./page/SalesmanPresence'));
const SalesmanLeaveHomepage = React.lazy(() => import('./page/SalesmanLeaveHomepage'));
const SalesmanLeave = React.lazy(() => import('./page/SalesmanLeave'));
const SalesmanLocation = React.lazy(() => import('./page/SalesmanLocation'));
const SalesmanMission = React.lazy(() => import('./page/SalesmanMission'));
const MissionTypeA = React.lazy(() => import('./page/MissionTypeA'));
const ChangeDevice = React.lazy(() => import('./page/ChangeDevice'));
const AuditRetail = React.lazy(() => import('./page/AuditRetail'));
const ChangeDeviceReport = React.lazy(() => import('./page/ChangeDeviceReport'));
const RetailLocation = React.lazy(() => import('./page/RetailLocation'));
const UnblockLoan = React.lazy(() => import('./page/UnblockLoan'));
const AuditUnblockRetail = React.lazy(() => import('./page/AuditUnblockRetail'));
const LoanReport = React.lazy(() => import('./page/LoanReport'));
const RetailProfile = React.lazy(() => import('./page/RetailProfile'));
const RetailProfileOrder = React.lazy(() => import('./page/RetailProfileOrder'));
const ProposeCreditList = React.lazy(() => import('./page/ProposeCreditList'));
const ProposeCreditForm = React.lazy(() => import('./page/ProposeCreditForm'));
const ProposeCreditAudit = React.lazy(() => import('./page/ProposeCreditAudit'));
const ProposeCreditReport = React.lazy(() => import('./page/ProposeCreditReport'));
const SalesmanPoints = React.lazy(() => import('./page/SalesmanPoints'));
const VisitProgress = React.lazy(() => import('./page/VisitProgress'));
const PresenceCalendar = React.lazy(() => import('./page/PresenceCalendar'));
const RouteLocation = React.lazy(() => import('./page/RouteLocation'));
const SalesmanCancelVisit = React.lazy(() => import('./page/SalesmanCancelVisit'));
const RetailDisplay = React.lazy(() => import('./page/RetailDisplay'));
const RetailDocNOO = React.lazy(() => import('./page/RetailDocNOO'));
// Photo Retail
const RetailProfilePhoto = React.lazy(() => import("./page/RetailProfilePhoto"));
// Photo Retail
const RetailPencarianLk = React.lazy(() => import("./page/RetailPencarianLk"));
// Display
const RetailProfileDisplay = React.lazy(() => import("./page/RetailProfileDisplay"));
// Voucher
const RandomVoucher = React.lazy(() => import("./page_external/RandomVoucher"));
// Ctt Penawaran
const CatatanPenawaran = React.lazy(() => import("./page/CatatanPenawaran"));
const CatatanPenawaranDetail = React.lazy(() => import("./page/CatatanPenawaranDetail"));
const CatatanPenawaranList = React.lazy(() => import("./page/CatatanPenawaranList"));
// Peserta Display
const ParticipantDisplayAdd = React.lazy(() => import("./page/ParticipantDisplayAdd"));
const ParticipantDisplayList = React.lazy(() => import("./page/ParticipantDisplayList"));
const ParticipantDisplayDetail = React.lazy(() => import("./page/ParticipantDisplayDetail"));
const ParticipantDisplayApproval = React.lazy(() => import("./page/ParticipantDisplayApproval"));
const ParticipantDisplayDetailApproval = React.lazy(() => import("./page/ParticipantDisplayDetailApproval"));
// Maps
const ChooseMap = React.lazy(() => import("./page/ChooseMap"))

function App() {
  return (
    <Router>
      <Suspense fallback={showRouterFallback()}>
        <Switch>
          <Route exact path='/'><Authentication/></Route>
          <Route path='/scan_visit'><ScanVisit/></Route>
          <Route path='/dashboard'><Dashboard/></Route>
          <Route path='/upload_photo'><UploadPhoto/></Route>
          <Route path='/status_photo'><StatusPhoto/></Route>
          <Route path='/retail_class'><RetailClassification/></Route>
          <Route path='/stock'><StockReport/></Route>
          <Route path='/salesman_wholesaler'><SalesmanWholesaler/></Route>
          <Route path='/salesman_home'><SalesmanHomepage/></Route>
          <Route path='/salesman_presence'><SalesmanPresence/></Route>
          <Route path='/salesman_leave_home'><SalesmanLeaveHomepage/></Route>
          <Route path='/salesman_leave'><SalesmanLeave/></Route>
          <Route path='/salesman_location'><SalesmanLocation/></Route>
          <Route path='/salesman_mission'><SalesmanMission/></Route>
          <Route path='/mission_typea'><MissionTypeA/></Route>
          <Route path='/change_device'><ChangeDevice/></Route>
          <Route path='/audit_retail'><AuditRetail/></Route>
          <Route path='/change_device_report'><ChangeDeviceReport/></Route>
          <Route path='/retail_location'><RetailLocation/></Route>
          <Route path='/unblock_lk'><UnblockLoan/></Route>
          <Route path='/audit_ubretail'><AuditUnblockRetail/></Route>
          <Route path='/loan_report'><LoanReport/></Route>
          <Route path='/retail_profile'><RetailProfile/></Route>
          <Route path='/retail_profile_order'><RetailProfileOrder/></Route>
          <Route path='/propose_credit'><ProposeCreditList/></Route>
          <Route path='/propose_credit_form'><ProposeCreditForm/></Route>
          <Route path='/propose_credit_audit'><ProposeCreditAudit/></Route>
          <Route path='/propose_credit_report'><ProposeCreditReport/></Route>
          <Route path='/salesman_points'><SalesmanPoints/></Route>
          <Route path='/visit_progress'><VisitProgress/></Route>
          <Route path='/presence_calendar'><PresenceCalendar/></Route>
          <Route path='/route_location'><RouteLocation/></Route>
          <Route path='/cancel_visit'><SalesmanCancelVisit/></Route>
          <Route path='/retail_profile_photo'><RetailProfilePhoto/></Route>
          <Route path='/retail_pencairan_lk'><RetailPencarianLk/></Route>
          <Route path='/retail_profile_display'><RetailProfileDisplay/></Route>
          <Route path='/retail_display'><RetailDisplay/></Route>
          <Route path='/retail_noo'><RetailDocNOO/></Route>
          <Route path='/ctt_penawaran'><CatatanPenawaran/></Route>
          <Route path='/ctt_penawaran_detail'><CatatanPenawaranDetail/></Route>
          <Route path='/ctt_penawaran_list'><CatatanPenawaranList/></Route>
          <Route path='/peserta_display'><ParticipantDisplayAdd/></Route>
          <Route path='/list_peserta_display'><ParticipantDisplayList/></Route>
          <Route path='/detail_peserta_display'><ParticipantDisplayDetail/></Route>
          <Route path='/list_approval_peserta_display'><ParticipantDisplayApproval/></Route>
          <Route path='/detail_approval_peserta_display'><ParticipantDisplayDetailApproval/></Route>

          <Route path='/random_voucher'>
            <RandomVoucher />
          </Route>

          <Route path='/choose_map'>
            <ChooseMap />
          </Route>

          <Route>
            <Redirect to="/" />
          </Route>
        </Switch>
      </Suspense>
    </Router>
  );
}

export default App;
